import axios from '@/plugins/axios';
import { OPERATION_TYPE, OPERATOR_TYPE } from './constants';

class StoreBindLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    type = OPERATION_TYPE.ALL,
    optGroupType = OPERATOR_TYPE.ALL,
    optNickName = '',
    storeName = '',
    commonStoreName = '',
  ) {
    return axios({
      url: '/api/sys/log/commonstore/page',
      data: {
        page,
        limit,
        type,
        opt_group_type: optGroupType, // TODO: 接口缺失参数
        opt_nick_name: optNickName,
        store_name: storeName,
        common_store_name: commonStoreName,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店关系绑定日志',
        },
      },
    });
  }

  static export({ ...data }) {
    return axios({
      url: '/api/sys/log/commonstore/export',
      method: 'post',
      data: {
        page: data.page,
        limit: data.limit,
        type: data.type,
        opt_group_type: data.optGroupType, // TODO: 接口缺失参数
        opt_nick_name: data.optNickName,
        store_name: data.storeName,
        common_store_name: data.commonStoreName,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default StoreBindLogApi;

import DataTransferUtil from '@/utils/data-transfer.util';

export const LOG_COLUMNS = [
  {
    title: '操作类型',
    key: 'type',
    slot: 'type',
    minWidth: 100,
  },
  {
    title: '关联门店',
    key: 'store_name',
    minWidth: 180,
  },
  {
    title: '被关联门店',
    key: 'common_store_name',
    minWidth: 180,
  },
  {
    title: '操作人类型',
    key: 'opt_group_type',
    slot: 'optGroupType',
    minWidth: 120,
  },
  {
    title: '操作企业',
    key: 'opt_group_name',
    minWidth: 120,
  },
  {
    title: '操作人',
    key: 'opt_nick_name',
    minWidth: 140,
  },
  {
    title: '操作时间',
    key: 'create_time',
    minWidth: 180,
  },
];

export const OPERATION_TYPE = {
  UNBIND: 0,
  BIND: 1,
  ALL: 'all',
};

export const BASIC_OPERATION_TYPE_TEXT = {
  [OPERATION_TYPE.UNBIND]: '解绑',
  [OPERATION_TYPE.BIND]: '绑定',
};

export const OPERATION_TYPE_TEXT = {
  ...BASIC_OPERATION_TYPE_TEXT,
  [OPERATION_TYPE.ALL]: '全部',
};

export const OPERATION_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(OPERATION_TYPE_TEXT);

export const OPERATOR_TYPE = {
  ADMIN: 'sys',
  CHANNEL: 'channel',
  OUTLET: 'store',
  CONSUMER: 'customer',
  ALL: 'all',
};

export const BASIC_OPERATOR_TYPE_TEXT = {
  [OPERATOR_TYPE.ADMIN]: '运营',
  [OPERATOR_TYPE.CHANNEL]: '渠道',
  [OPERATOR_TYPE.OUTLET]: '门店',
  [OPERATOR_TYPE.CONSUMER]: '消费者',
};

export const OPERATOR_TYPE_TEXT = {
  ...BASIC_OPERATOR_TYPE_TEXT,
  [OPERATOR_TYPE.ALL]: '全部',
};

export const OPERATOR_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(OPERATOR_TYPE_TEXT);
